/** @jsx jsx */
import { jsx } from "theme-ui"
import { RevealIcon } from "."

const RevealButton = props => {
  const { question, questionExpanded, setQuestionExpanded } = props
  return (
    <button
      type="button"
      className="toggle-button"
      onClick={() => setQuestionExpanded(!questionExpanded)}
      aria-label={`See answer to "${question.text}"`}
      sx={{
        color: "inherit",
        fontFamily: "inherit",
        fontSize: "inherit",
        background: "none",
        border: "none",
        appearance: "none",
        borderRadius: 0,
        resize: "none",
        width: "100%",
        display: "flex",
        textAlign: "left",
        flexDirection: ["row", "row", "row", "row"],
        alignItems: ["center", "center", "center", "center"],
        justifyContent: [
          "space-between",
          "space-between",
          "space-between",
          "space-between",
        ],
        padding: ["0", "0", "0", "0"],
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <h2
        className="the-question"
        sx={{
          color: "inherit",
          maxWidth: "500px",
          lineHeight: 1.1,
          fontSize: [20, 22, 24, 26],
          margin: 0,
          padding: 0,
        }}
      >
        {question.text}
      </h2>
      <RevealIcon questionExpanded={questionExpanded} />
    </button>
  )
}

export default RevealButton
