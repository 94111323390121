/** @jsx jsx */
import { jsx, Box, Link, Heading } from "theme-ui"

function FeedbackFeatureRequest(props) {
  const { ...rest } = props
  return (
    <Box {...rest}>
      <Heading mt={4} mb={3} as="h2">
        Feedback & Feature Request
      </Heading>
      <Link
        sx={{ textDecoration: "none" }}
        href="https://forms.gle/WNpMZiAEyBCu1NEaA"
        target="_blank"
      >
        <span role="img" aria-label="waving">
          👋
        </span>{" "}
        Submit feedback or a feature request
      </Link>
    </Box>
  )
}

export default FeedbackFeatureRequest
