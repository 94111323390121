/** @jsx jsx */
import { jsx } from "theme-ui"

const HtmlContent = ({ content }) => (
  <div
    className="gatsby-theme-faqs-html-content"
    dangerouslySetInnerHTML={{ __html: content }}
    sx={{
      padding: ["2px 0 16px", "2px 0 20px", "2px 0 24px", "2px 0 28px"],
    }}
  />
)

export default HtmlContent
