/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { Link } from "gatsby"

import Logo from "../Logo"

function TopNavigation() {
  return (
    <Link to="/">
      <Box
        mx="auto"
        py={3}
        sx={{ maxWidth: 900, textAlign: "left", width: "100%" }}
      >
        <Logo size={16} />
      </Box>
    </Link>
  )
}

export default TopNavigation
