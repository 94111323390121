/** @jsx jsx */
import { useCallback, useState, useEffect } from "react"
import { jsx, Heading, Paragraph, Divider } from "theme-ui"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import cloneDeep from "lodash.clonedeep"

import TopNavigation from "../components/TopNavigation"
import ControlPanel from "../components/faq/ControlPanel"
import QuestionsList from "../components/faq/QuestionsList"
import FeedbackFeatureRequest from "../components/FeedbackFeatureRequest"

import Layout from "../layout"

function FaqPage(props) {
  const { data, location } = props
  const {
    page: {
      data: { questions },
    },
  } = data

  const [visibleQuestions, setVisibleQuestions] = useState([])
  const [activeCategory, setActiveCategory] = useState(null)
  const [searchQuery, setSearchQuery] = useState("")
  const [searchActive, setSearchActive] = useState(false)

  const categories = questions.map(
    faqItem => faqItem.category.document.data.categoryName.text
  )
  const uniqueCategories = [...new Set(categories)]

  // SEO, let questions be searchable
  const faqSchemaData =
    questions &&
    questions.length > 0 &&
    questions.map(questionItem => {
      const { question, answer } = questionItem
      return {
        "@type": "Question",
        name: question.text,
        acceptedAnswer: {
          "@type": "Answer",
          text: answer.text,
        },
      }
    })

  useEffect(() => {
    let newVisibleQuestions = cloneDeep(questions)
    if (searchQuery) {
      newVisibleQuestions = questions.filter(faqItem =>
        faqItem.question.text.toLowerCase().includes(searchQuery)
      )
    }
    if (activeCategory) {
      newVisibleQuestions = newVisibleQuestions.filter(faqItem => {
        const thisCategory = faqItem.category.document.data.categoryName.text
        return activeCategory === thisCategory
      })
    }
    setVisibleQuestions(newVisibleQuestions)
  }, [activeCategory, searchQuery, questions])

  const setSearchHandler = useCallback(event => {
    setSearchQuery(event.target.value)
  }, [])

  const setCategoryHandler = useCallback(
    (event, category) => {
      if (event) event.preventDefault()
      if (category !== activeCategory) {
        setActiveCategory(category)
      }
    },
    [activeCategory]
  )

  const searchFocusHandler = useCallback((event, clicked) => {
    event.preventDefault()
    setSearchActive(clicked)
  }, [])

  return (
    <Layout title="Knowledge Center">
      <Helmet>
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(faqSchemaData)}
        </script>
      </Helmet>
      <TopNavigation />
      <Heading mt={4} mb={3} as="h1">
        Amooto Knowledge Center
      </Heading>
      <Paragraph mb={2}>
        There are a lot of questions we get asked often, so we figured we'd
        start writing them down{" "}
        <span role="img" aria-label="smiley">
          🙂
        </span>
      </Paragraph>
      <Paragraph mb={2}>
        We’ll continue adding to this as we get more great questions!
      </Paragraph>
      <ControlPanel
        activeCategory={activeCategory}
        uniqueCategories={uniqueCategories}
        setActiveCategoryHandler={setCategoryHandler}
        searchActive={searchActive}
        searchQuery={searchQuery}
        searchFocusHandler={searchFocusHandler}
        setSearchHandler={setSearchHandler}
        numResults={visibleQuestions.length}
      />
      <QuestionsList questions={visibleQuestions} location={location} />
      <Divider mt={4} />
      <FeedbackFeatureRequest />
    </Layout>
  )
}

export default FaqPage

export const pageQuery = graphql`
  query FrequentlyAskedQuestionsQuery {
    page: prismicFrequentlyAskedQuestions {
      uid
      data {
        pageTitle: page_name {
          text
        }
        heroSubtitle: hero_subtitle {
          text
        }
        questions {
          question {
            text
          }
          answer {
            text
            html
          }
          category {
            document {
              ... on PrismicQuestionCategory {
                id
                data {
                  categoryName: category_name {
                    text
                  }
                }
              }
            }
          }
        }
        metaTitle: meta_title {
          html
          text
        }
        metaDescription: meta_description {
          html
          text
        }
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
      }
    }
  }
`
