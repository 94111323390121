/** @jsx jsx */
import { jsx } from "theme-ui"

const Wrapper = props => {
  const { children } = props
  return (
    <div
      className="wrapper"
      sx={{
        width: "100%",
        maxWidth: "wrapper",
        marginLeft: "auto",
        marginRight: "auto",
        padding: 0,
      }}
    >
      {children}
    </div>
  )
}

export default Wrapper
